import { navigateTo, defineNuxtRouteMiddleware } from '#imports';

const { isLoggedIn, currentUser } = useSitisAuth();

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) {
    if (!isLoggedIn.value) {
      return navigateTo('/authorization');
    }
  }
});
